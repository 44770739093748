import React, { useState } from "react"
import { Link } from "gatsby"
import { Box, Flex, Image, Text } from "rebass/styled-components"
import { withTheme } from "styled-components"
import { connect } from "react-redux"
import { getNodePath } from "../../utils"
import RcDrawer from "rc-drawer"
import "rc-drawer/assets/index.css"
import SearchBar from "../UI/SearchBar"
import MenuMobile from "./MenuMobile"
import IconPlan from "../UI/icons/IconPlan"
import UserMenu from "./UserMenu"

const Header = ({ isMap, hasCurrent, node, theme, location, isSticky }) => {
  const [open, setOpen] = useState(false)
  const toggleDrawer = () => {
    setOpen(!open)
  }

  const hideButtonPaths = [
    "/mentions-legales",
    "/cgu",
    "/confidentialite",
    "/cookies",
    "/mon-compte",
    "/mentions-legales/",
    "/cgu/",
    "/confidentialite/",
    "/cookies/",
    "/mon-compte/",
  ]

  isSticky = isSticky || false
  const toggleButton = isMap
    ? {
        label: "Afficher sur l'annuaire",
        icon: "annuaire",
      }
    : {
        label: "Afficher sur le plan",
        icon: "plan-carte",
      }
  var to = hasCurrent
    ? getNodePath(node, !isMap)
    : location
    ? location.pathname
    : "/"
  var linkState = hasCurrent ? {} : { isMap: !isMap }
  if (location) {
    if (
      location.pathname === "/" ||
      location.pathname === "/annuaire" ||
      location.pathname === "/annuaire/"
    ) {
      to = location.pathname === "/" ? "/annuaire" : "/"
    }
  }
  return (
    <Box height={["50px", "50px", "70px"]}>
      <Box variant="navbar">
        <Box height={["50px", "50px", "70px"]}>
          <Flex alignItems="stretch">
            <Box
              width={["61px", "61px", "auto"]}
              minWidth={["61px", "61px", "auto"]}
            >
              <Link
                to={isMap ? "/" : "/annuaire"}
                className="logo"
                state={{ isMap: isMap }}
              >
                <Image
                  display={["none", "none", "block"]}
                  height={["50px", "50px", "70px"]}
                  src="/logo-head.svg"
                  alt="Plan.nc"
                />
                <Image
                  display={["block", "block", "none"]}
                  height="50px"
                  maxWidth="61px"
                  src="/logo-mobile.svg"
                  alt="Plan.nc"
                />
              </Link>
            </Box>
            <Flex alignItems="center" flexGrow="1" flexShrink="1">
              <SearchBar id="search-in-head" width="100%" />
            </Flex>
            <Flex
              minWidth={["50px", "50px"]}
              alignItems="stretch"
              justifyContent={["center", "center", "flex-end"]}
              pr={[0, 0, 2]}
              ml={[-2, -2, 0]}
            >
              <Box my={2} display={["none", "none", "flex"]}>
                <Flex
                  variant="headerButtonActive"
                  color="white"
                  sx={{
                    background:
                      "linear-gradient(161deg,#F761A1 0%,#8C1BAB 100%)",
                  }}
                  mr={[2]}
                >
                  <Link
                    replace={false}
                    to={"/rendez-vous/rdv-en-ligne"}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                    }}
                  >
                    <Flex alignItems="center">
                      <IconPlan icon="calendar-squares" size="24" />
                      <Text
                        className="header-text"
                        pl={[0, 0, 3]}
                        display={["none", "none", "flex"]}
                      >
                        RDV en ligne
                      </Text>
                    </Flex>
                  </Link>
                </Flex>
                {hideButtonPaths.indexOf(location.pathname) === -1 && (
                  <Flex variant="headerButtonActive">
                    <Link
                      replace={false}
                      to={to}
                      state={linkState}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: theme.colors.text,
                      }}
                    >
                      <Flex alignItems="center">
                        <IconPlan icon={toggleButton.icon} size="24" />
                        <Text
                          className="header-text"
                          pl={[0, 0, 3]}
                          display={["none", "none", "flex"]}
                        >
                          {toggleButton.label}
                        </Text>
                      </Flex>
                    </Link>
                  </Flex>
                )}
                <UserMenu />
              </Box>
              <IconPlan
                alignItems="center"
                sx={{ cursor: "pointer" }}
                onClick={toggleDrawer}
                display={["flex", "flex", "none"]}
                icon="menu"
                size="24"
              />
            </Flex>
          </Flex>
        </Box>
      </Box>
      <Box sx={{ position: "relative" }}>
        <RcDrawer
          placement="right"
          handler={false}
          width="100%"
          open={open}
          onClose={toggleDrawer}
        >
          <Box onClick={toggleDrawer}>
            <IconPlan
              pt={3}
              pr={2}
              justifyContent="flex-end"
              sx={{ cursor: "pointer" }}
              color="primary"
              icon="close"
              size="24"
              onClick={toggleDrawer}
            />

            <MenuMobile />
          </Box>
        </RcDrawer>
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  isMap: state.global.isMap,
  location: state.global.location,
  hasCurrent: state.currentNode.hasCurrent,
  node: state.currentNode.item,
})
export default connect(mapStateToProps)(withTheme(Header))

import React from "react"
import { Text, Flex, Box } from "rebass/styled-components"
import { Link } from "gatsby"
import unescape from "lodash/unescape"
import { connect } from "react-redux"

import IconPlan from "./icons/IconPlan"

const Breadcrumb = ({ location, isMap, crumbs, ...props }) => {
  crumbs = crumbs || []
  return (
    <Box {...props}>
      <Flex py={isMap ? 2 : 4}>
        <Flex alignItems="center">
          <Link to={isMap ? "/" : "/annuaire"} state={{ isMap: isMap }}>
            <Flex alignItems="center">
              <IconPlan icon="home_fill" size="16" color="primary" />
              <Text pl={2} fontSize="11rem">
                Accueil
              </Text>
            </Flex>
          </Link>
        </Flex>
        {crumbs.length > 0 && (
          <>
            {crumbs.map((crumb, index) => (
              <Flex key={"breadcrumb-" + index} alignItems="center">
                <IconPlan icon="chevron_right" size="24" color="gray" />
                {crumb.path && (
                  <Link
                    to={crumb.path === "/recherche/" ? "/" : crumb.path}
                    state={{ isMap: isMap }}
                  >
                    <Text
                      fontSize="11rem"
                      fontWeight={
                        index + 1 === crumbs.length ? "bold" : "normal"
                      }
                    >
                      {unescape(crumb.title)}
                    </Text>
                  </Link>
                )}
                {!crumb.path && (
                  <Text
                    fontSize="11rem"
                    fontWeight={index + 1 === crumbs.length ? "bold" : "normal"}
                  >
                    {unescape(crumb.title)}
                  </Text>
                )}
              </Flex>
            ))}
          </>
        )}
      </Flex>
    </Box>
  )
}
const mapStateToProps = state => ({
  location: state.global.location,
  isMap: state.global.isMap,
})
export default connect(mapStateToProps)(Breadcrumb)

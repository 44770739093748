import React from "react"
import { Box } from "rebass/styled-components"
const Container = props => {
  return (
    <Box variant="container" {...props}>
      {props.children}
    </Box>
  )
}

export default Container

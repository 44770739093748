import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Box, Image, Flex, Text, Button } from "rebass/styled-components"
import { connect } from "react-redux"
import { getNodePath } from "../../utils"
import { userMenuItems } from "./UserMenu"
import "rc-drawer/assets/index.css"

import IconPlan from "../UI/icons/IconPlan"
const MenuLink = ({ icon, label, iconColor, ...props }) => {
  iconColor = iconColor || "textColor"
  return (
    <Button variant="ninja" width={[1]} py={2} px={3} {...props}>
      <Flex alignItems="center">
        <IconPlan color={iconColor} mr={2} icon={icon} size="24" />
        <Text fontWeight="bold">{label}</Text>
      </Flex>
    </Button>
  )
}

const MenuLinkSimple = ({ to, children }) => (
  <Link to={to}>
    <Text fontSize={1} py={2} px={3}>
      {children}
    </Text>
  </Link>
)

const MenuMobile = ({ isMap, hasCurrent, node, location, user, dispatch }) => {
  const hideButtonPaths = [
    "/mentions-legales",
    "/cgu",
    "/confidentialite",
    "/cookies",
    "/mon-compte",
    "/mentions-legales/",
    "/cgu/",
    "/confidentialite/",
    "/cookies/",
    "/mon-compte/",
  ]

  const toggleButton = isMap
    ? {
        label: "Afficher sur l'annuaire",
        icon: "annuaire",
      }
    : {
        label: "Afficher sur le plan",
        icon: "plan-carte",
      }

  const { lesEntreprisesJson } = useStaticQuery(
    graphql`
      query {
        lesEntreprisesJson(nid: { eq: "3097" }) {
          path {
            path
          }
        }
      }
    `
  )
  var to = hasCurrent
    ? getNodePath(node, !isMap)
    : location
    ? location.pathname
    : "/"
  var linkState = hasCurrent ? {} : { isMap: !isMap }
  if (location) {
    if (
      location.pathname === "/" ||
      location.pathname === "/annuaire" ||
      location.pathname === "/annuaire/"
    ) {
      to = location.pathname === "/" ? "/annuaire" : "/"
    }
  }
  return (
    <>
      <Image
        sx={{ position: "absolute", top: 0, left: 0 }}
        height={["50px", "50px", "70px"]}
        mt={2}
        ml={2}
        src="/logo-head.svg"
        alt="Plan.nc"
      />
      <Flex
        p={4}
        pt={6}
        justifyContent="stretch"
        flexDirection="column"
        textAlign="left"
      >
        <Link to="/rendez-vous/rdv-en-ligne">
          <MenuLink
            icon="calendar-squares"
            label="Rdv en ligne"
            color="white"
            sx={{
              background: "linear-gradient(161deg,#F761A1 0%,#8C1BAB 100%)",
borderRadius: "4px",
            }}
          />
        </Link>
        {hideButtonPaths.indexOf(location.pathname) === -1 && (
          <Link replace={false} to={to} state={linkState}>
            <MenuLink {...toggleButton} iconColor="primary" />
          </Link>
        )}
        {!user && (
          <Link to="/mon-compte">
            <MenuLink icon="user_fill" iconColor="primary" label="Mon compte" />
          </Link>
        )}

        <Link to={lesEntreprisesJson.path.path}>
          <MenuLink
            icon="message_outline-copy"
            iconColor="primary"
            label="Contacter Plan.nc"
          />
        </Link>
        <a role="button" aria-label="Appeler Plan.nc" href="tel:+687271284">
          <MenuLink
            icon="call_outline-copy"
            iconColor="primary"
            label="Appeler Plan.nc"
          />
        </a>
        {user && (
          <>
            <Box my={6} height="1px" bg="#d8d8d8" />
            {userMenuItems.map(item => (
              <Link to={item.link} key={item.id}>
                <MenuLink {...item} />
              </Link>
            ))}
          </>
        )}
        <Box my={6} height="1px" bg="#d8d8d8" />
        <MenuLinkSimple to="/mentions-legales">Mentions légales</MenuLinkSimple>
        <MenuLinkSimple to="/cgu">CGU</MenuLinkSimple>
        <MenuLinkSimple to="/confidentialite">Confidentialité</MenuLinkSimple>
        <MenuLinkSimple to="/cookies">Utilisation des cookies</MenuLinkSimple>
      </Flex>
      <Box sx={{ position: "absolute", bottom: 0 }} width="100%" px={4} pb={2}>
        <Text color="gray" fontSize={0}>
          © Plan.nc - {new Date().getFullYear()} - Réalisation : La Fabrik
        </Text>
      </Box>
    </>
  )
}

const mapStateToProps = state => ({
  isMap: state.global.isMap,
  location: state.global.location,
  hasCurrent: state.currentNode.hasCurrent,
  node: state.currentNode.item,
  user: state.currentUser.user,
})
export default connect(mapStateToProps)(MenuMobile)

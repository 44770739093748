import React from "react"
import { Box, Button, Flex } from "rebass/styled-components"
import { connect } from "react-redux"
import {
  toggleMapDrawer,
  setMapDrawerLevel,
} from "../../redux/actions/globalAction"
import Breadcrumb from "../UI/Breadcrumb"
import loadable from "@loadable/component"

const Filters = loadable(() => import("../UI/SearchBar/Filters"))

const Drawer = ({
  children,
  mapDrawerOpen,
  mapDrawerLevel,
  dispatch,
  drawerWidth,
  crumbs,
  showCrumbs,
}) => {
  const toggleDrawer = () => {
    dispatch(toggleMapDrawer())
  }

  const changeDrawerLevel = plus => {
    plus = plus || false
    const newLevel = plus
      ? mapDrawerLevel < 2
        ? mapDrawerLevel + 1
        : mapDrawerLevel
      : mapDrawerLevel > 0
      ? mapDrawerLevel - 1
      : mapDrawerLevel
    dispatch(setMapDrawerLevel(newLevel))
  }
  const heights = [0, 0.5, 0.92]
  const buttonStyle = {
    top: ["-91px", "-91px", "50%"],
    right: ["50%", "50%", "-43px"],
    marginTop: ["0", "0", "-75px"],
    marginRight: ["-24px", "-24px", "0"],
    position: "absolute",
    zIndex: 2,
    transform: ["rotate(-90deg)", "rotate(-90deg)", "none"],
    "&:hover": {
      right: ["50%", "50%", "-48px"],
    },
  }
  return (
    <Box
      height={[
        heights[mapDrawerLevel] * 100 + "%",
        heights[mapDrawerLevel] * 100 + "%",
        "100%",
      ]}
      bg="white"
      width={[1, 1, drawerWidth]}
      sx={{
        position: "absolute",
        top: [
          (1 - heights[mapDrawerLevel]) * 100 + "%",
          (1 - heights[mapDrawerLevel]) * 100 + "%",
          "0",
        ],
        left: mapDrawerOpen ? [0] : ["0", "0", "-" + drawerWidth + ""],
        zIndex: "1",
        transition: "all 300ms ease",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.4)",
      }}
    >
      <Flex
        bg="white"
        flexDirection="column"
        width="100%"
        height="100%"
        sx={{
          position: "absolute",
          zIndex: 3,
          display: mapDrawerOpen ? "inherit" : "none !important",
        }}
      >
        <Box bg="white" sx={{ position: "relative", zIndex: "3" }}>
          {showCrumbs && <Breadcrumb crumbs={crumbs} pl={2} />}
          <Filters />
        </Box>
        <Box
          sx={{
            overflow: "auto",
            overflowY: "scroll",
            "-webkit-overflow-scrolling": "touch",
          }}
          p={mapDrawerOpen ? 2 : 0}
          pt={0}
          id="map-content-wrapper"
        >
          {children}
        </Box>
      </Flex>

      <Button
        sx={buttonStyle}
        variant="transparent"
        p="0"
        color="primary"
        aria-label={
          mapDrawerOpen ? "Masquer le bandeau" : "Afficher le bandeau"
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48px"
          viewBox="0 0 40 110"
          style={{ filter: "drop-shadow( 0px 0px 10px rgba(0,0,0,0.4))" }}
        >
          <path
            fill="currentColor"
            d="M0 0l33.046 10.567A10 10 0 0140 20.092v69.816a10 10 0 01-6.954 9.525L0 110V0z"
          />
        </svg>
        <Box
          onClick={toggleDrawer}
          display={["none", "none", "block"]}
          position="absolute"
          py="57px"
          px="18px"
          sx={{
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            position: "absolute",
            zIndex: 2,
            transition: "all 300ms ease",
            transform: mapDrawerOpen ? "rotate(0deg)" : "rotate(180deg)",
            color: "white",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 8 12"
            width="12px"
            height="18px"
          >
            <g fill="none" fillRule="evenodd">
              <path d="M-7-6h24v24H-7z" />
              <path
                fill="currentColor"
                d="M7.568 1.734L3.27 6.032l4.298 4.297-1.415 1.414L.442 6.032 6.153.32z"
              />
            </g>
          </svg>
        </Box>
        <Box
          onClick={() => changeDrawerLevel()}
          display={["block", "block", "none"]}
          position="absolute"
          px="18px"
          pt="30px"
          pb="18px"
          sx={{
            top: "0",
            right: "0",
            position: "absolute",
            zIndex: 2,
            transition: "all 300ms ease",
            transform: "rotate(0deg)",
            color: mapDrawerLevel === 0 ? "primaryDark" : "white",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 8 12"
            width="12px"
            height="18px"
          >
            <g fill="none" fillRule="evenodd">
              <path d="M-7-6h24v24H-7z" />
              <path
                fill="currentColor"
                d="M7.568 1.734L3.27 6.032l4.298 4.297-1.415 1.414L.442 6.032 6.153.32z"
              />
            </g>
          </svg>
        </Box>
        <Box
          onClick={() => changeDrawerLevel(true)}
          display={["block", "block", "none"]}
          position="absolute"
          px="18px"
          pt="30px"
          pb="18px"
          sx={{
            bottom: "0",
            right: "0",
            position: "absolute",
            zIndex: 2,
            transition: "all 300ms ease",
            transform: "rotate(180deg)",
            color: mapDrawerLevel === 2 ? "primaryDark" : "white",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 8 12"
            width="12px"
            height="18px"
          >
            <g fill="none" fillRule="evenodd">
              <path d="M-7-6h24v24H-7z" />
              <path
                fill="currentColor"
                d="M7.568 1.734L3.27 6.032l4.298 4.297-1.415 1.414L.442 6.032 6.153.32z"
              />
            </g>
          </svg>
        </Box>
      </Button>
    </Box>
  )
}
const mapStateToProps = state => ({
  location: state.global.location,
  mapDrawerOpen: state.global.mapDrawerOpen,
  mapDrawerLevel: state.global.mapDrawerLevel,
  hasCurrent: state.currentNode.hasCurrent,
})
export default connect(mapStateToProps)(Drawer)

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React, { useEffect } from "react"
import { connect } from "react-redux"
import AnnuaireLayout from "./AnnuaireLayout"
import MapLayout from "./MapLayout"
import { setIsMap, setIsMobile } from "../../redux/actions/globalAction"
import {
  currentSession,
  getToken,
  getUser,
  removeCurrentUser,
} from "../../redux/actions/currentUserActions"

const Layout = ({
  isMap,
  isMapRedux,
  user,
  dispatch,
  location,
  hasCurrent,
  node,
  ...props
}) => {
  isMap = isMap === undefined ? isMapRedux : isMap
  useEffect(() => {
    if (isMap !== isMapRedux) {
      dispatch(setIsMap(isMap))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMap])
  useEffect(() => {
    if (location) {
      if (location.pathname.indexOf("/user/reset/") !== 0) {
        dispatch(getToken(true)).then(() => {
          dispatch(currentSession()).then(actionSession => {
            if (actionSession.type === "USER_SESSION_SUCCESS") {
              if (actionSession.payload.data.user) {
                dispatch(getUser(actionSession.payload.data.user.uid))
              } else {
                dispatch(removeCurrentUser())
              }
            }
          })
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])
  useEffect(() => {
    const { isMobile } = require("mobile-device-detect")
    if (isMobile) {
      dispatch(setIsMobile(true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return isMap ? <MapLayout {...props} /> : <AnnuaireLayout {...props} />
}

const mapStateToProps = state => ({
  isMapRedux: state.global.isMap,
  user: state.currentUser.user,
  location: state.global.location,
  hasCurrent: state.currentNode.hasCurrent,
  node: state.currentNode.item,
})
export default connect(mapStateToProps)(Layout)

import React, { useEffect } from "react"
import { Box } from "rebass/styled-components"

import { currentNodeReset } from "../redux/actions/currentNodeActions"
import { setLocation, setShowFilters } from "../redux/actions/globalAction"
import { connect } from "react-redux"
import { getNodePath } from "../utils"
import SEO from "../components/layout/seo"
import Layout from "../components/layout"

const Page = ({
  crumbs,
  dispatch,
  children,
  title,
  location,
  currentLocation,
  hasCurrent,
  description,
  filters,
  showFilters,
  node,
  image,
  keywords,
  forceMap,
  showCrumbs,
}) => {
  filters = filters || false
  showCrumbs = showCrumbs || false
  crumbs = crumbs || [{ title: title, path: location.pathname }]
  useEffect(() => {
    if (typeof window !== "undefined") {
      //   if (location.search !== "") {
      //     const quer = location.search
      //     if (quer.indexOf("annuaire") > -1) {
      //       dispatch(setIsMap(false))
      //     }
      //     if (quer.indexOf("map") > -1) {
      //       dispatch(setIsMap(true))
      //     }
      //     navigate(location.pathname)
      //   }
    }
    if (currentLocation) {
      if (currentLocation.pathname !== location.pathname) {
        if (hasCurrent) {
          if (
            location.pathname !== getNodePath(node, true) &&
            location.pathname !== getNodePath(node, false)
          ) {
            dispatch(currentNodeReset())
          }
        }
      }
    } else {
      if (hasCurrent) {
        dispatch(currentNodeReset())
      }
    }
    dispatch(setLocation(location))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])
  useEffect(() => {
    var mapWrapper = document.getElementById("map-content-wrapper")
    if (mapWrapper) {
      mapWrapper.scrollTop = 0
    }
    if (showFilters !== filters) {
      dispatch(setShowFilters(filters))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  title = title || ""
  description = description || ""
  return (
    <Layout crumbs={crumbs} isMap={forceMap} showCrumbs={showCrumbs}>
      <Box pb={["100px", "100px", 5]}>
        <SEO
          title={title}
          description={description}
          image={image}
          keywords={keywords}
        />
        {children}
      </Box>
    </Layout>
  )
}
const mapStateToProps = state => ({
  currentLocation: state.global.location,
  showFilters: state.global.showFilters,
  hasCurrent: state.currentNode.hasCurrent,
  node: state.currentNode.item,
})
export default connect(mapStateToProps)(Page)

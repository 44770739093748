import React from "react";
import { Flex } from 'rebass/styled-components';
require("./plan_icon/style.css");

const IconPlan = ({ icon, style, size, ...props }) => {
  if (size) {
    style = style || {};
    style.width = /^\d+$/.test(size) ? size + 'px' : size;
    style.fontSize = /^\d+$/.test(size) ? size + 'px' : size;
  }
  return <Flex alignItems="center" justifyContent="center" {...props}><span className={"icon-plan-" + icon} style={style}></span></Flex>;
};

export default IconPlan;

import React from "react"
import { Spinner } from "react-awesome-spinners"
import { Box, Text } from "rebass/styled-components"
import { withTheme } from "styled-components"

const Loader = ({ message, theme, ...props }) => {
  message = message || "Recherche en cours"
  return (
    <Box py={4} textAlign="center" width="100%" {...props}>
      <Spinner color={theme.colors.primary} />
      <Box pt={4} textAlign="center">
        <Text>{message}</Text>
      </Box>
    </Box>
  )
}
export default withTheme(Loader)

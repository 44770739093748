import React, { useState, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Box, Flex, Button, Text } from "rebass/styled-components"
// import Cookies from "./Cookies"
import IconPlan from "../UI/icons/IconPlan"
function isIpadPro() {
  var ratio = window.devicePixelRatio || 1
  var screen = {
    width: window.screen.width * ratio,
    height: window.screen.height * ratio,
  }
  return (
    (screen.width === 2048 && screen.height === 2732) ||
    (screen.width === 2732 && screen.height === 2048) ||
    (screen.width === 1536 && screen.height === 2048) ||
    (screen.width === 2048 && screen.height === 1536)
  )
}
const Footer = () => {
  const [isIpad, setIsIpad] = useState(false)
  useEffect(() => {
    if (window) {
      setIsIpad(isIpadPro())
    }
  }, [])
  const { lesEntreprisesJson } = useStaticQuery(
    graphql`
      query {
        lesEntreprisesJson(nid: { eq: "3097" }) {
          path {
            path
          }
        }
      }
    `
  )
  return (
    <>
      <Box
        bg="grayLight"
        py={2}
        pb={isIpad ? 4 : 2}
        fontSize="13rem"
        display={["none", "none", "block"]}
      >
        <Box variant="containerFull">
          <Flex flexDirection={["column", "column", "row"]} alignItems="center">
            <Button variant="footerLink" sx={{ borderLeft: "none" }}>
              <Link to="/mentions-legales">Mentions légales</Link>
            </Button>
            <Button variant="footerLink">
              <Link to="/cgu">CGU</Link>
            </Button>
            <Button variant="footerLink">
              <Link to="/confidentialite">Confidentialité</Link>
            </Button>
            <Button variant="footerLink">
              <Link to="/cookies">Utilisation des cookies</Link>
            </Button>
            <Flex
              px={2}
              flex="1"
              justifyContent={["center", "center", "flex-end"]}
            >
              <Link to={lesEntreprisesJson.path.path}>
                <Flex alignItems="center" mr={4}>
                  <IconPlan
                    pr={2}
                    icon="message_outline-copy"
                    size="24"
                    color="primary"
                  />
                  <Text color="text" fontWeight="bold">
                    Contacter l’équipe Plan.nc
                  </Text>
                </Flex>
              </Link>
              <a href="tel:+687271284">
                <Flex alignItems="center">
                  <IconPlan
                    pr={2}
                    icon="call_outline-copy"
                    size="24"
                    color="primary"
                  />
                  <Text color="text" fontWeight="bold">
                    27 12 84
                  </Text>
                </Flex>
              </a>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </>
  )
}

export default Footer

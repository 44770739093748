import React from "react"
import { Box, Flex } from "rebass/styled-components"
const RatioBox = ({ ratio, children, ...props }) => {
  const ratioArray = Array.isArray(ratio) ? ratio : [ratio]
  const responsiveRatio = ratioArray.map(ratioValue => {
    return typeof ratioValue === "string" && ratioValue.indexOf("%") > -1
      ? ratioValue
      : Math.round(parseFloat(ratioValue) * 100 * 100) / 100 + "%"
  })
  return (
    <Box
      sx={{
        position: "relative",
        "&:after": {
          content: "''",
          display: "block",
          paddingBottom: responsiveRatio,
        },
      }}
      {...props}
    >
      <Flex
        sx={{ position: "absolute" }}
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        {children}
      </Flex>
    </Box>
  )
}

export default RatioBox
